import React, { useEffect, useState } from 'react';
import './home.scss';
import { LuUsers2, LuServer } from "react-icons/lu";
import { RiComputerLine } from "react-icons/ri";
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsers } from '../../redux/actions/userActions';
import { fetchGroups } from '../../redux/actions/groupActions';
import { FaDotCircle } from "react-icons/fa";
import { IoPersonAddOutline, IoSearch } from "react-icons/io5";
import { registerUser } from '../../redux/actions/registerActions';
import Modal from './Modal/Modal';
import Toast from './Toast/Toast'; 
import Loader from '../Loader/Loader';
import { NavLink } from 'react-router-dom';
const Home = () => {
    const [fullName, setFullName] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [city, setCity] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [showToast, setShowToast] = useState(false); 
    const [searchQuery, setSearchQuery] = useState('');

    const itemsPerPage = 5;
    const dispatch = useDispatch();

    const { loading: usersLoading, users, error: usersError } = useSelector((state) => state.users);
    const { loading: groupsLoading, specialGroups, ordinaryGroups, error: groupsError } = useSelector((state) => state.groups);
    const { loading, userInfo } = useSelector((state) => state.auth);

    useEffect(() => {
        dispatch(fetchUsers());
        dispatch(fetchGroups());
    }, [dispatch]);

    useEffect(() => {
        console.log('Users:', users); // Add this to see if isActive is correctly fetched
    }, [users]);

    useEffect(() => {
        function closeToast(event) {
            let toastClose = event.target;
            toastClose.closest('.toast').remove();
        }

        function closeToastDuration() {
            if (document.querySelectorAll('.toast')) {
                let toasts = document.querySelectorAll('.toast');
                toasts.forEach(toast => {
                    setTimeout(() => {
                        toast.remove();
                    }, 10000);
                });
            }
        }

        closeToastDuration();

        if (document.getElementById('toast-container')) {
            let mutationObserver = new MutationObserver(function (mutations) {
                mutations.forEach(function (mutation) {
                    closeToastDuration();
                });
            });
            mutationObserver.observe(document.getElementById('toast-container'), {
                attributes: true,
                characterData: true,
                childList: true,
                subtree: true,
                attributeOldValue: true,
                characterDataOldValue: true
            });
        }
    }, []);

    const isLoading = usersLoading || groupsLoading;
    const error = usersError || groupsError;

    if (isLoading) return <Loader />;
    if (error) return <p>Error: {error}</p>;

    // Filter out the Admin user
    const nonAdminUsers = users.filter(user => !user.isAdmin);

    const activeUsers = nonAdminUsers.filter(user => user.isActive);
    const activeUserCount = activeUsers.length;
    const userCount = nonAdminUsers.length;
    const groupCount = specialGroups.length + ordinaryGroups.length;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = nonAdminUsers.slice(indexOfFirstItem, indexOfFirstItem + itemsPerPage);

    const totalPages = Math.ceil(userCount / itemsPerPage);

    const handleClick = (event, page) => {
        event.preventDefault();
        setCurrentPage(page);
    };

    const filteredItems = currentItems.filter(user => {
        const fullName = user.fullName ? user.fullName.toLowerCase() : '';
        const username = user.username ? user.username.toLowerCase() : '';
        const city = user.city ? user.city.toLowerCase() : '';

        return (
            fullName.includes(searchQuery.toLowerCase()) ||
            username.includes(searchQuery.toLowerCase()) ||
            city.includes(searchQuery.toLowerCase())
        );
    });


    const handleSubmit = (e) => {
        e.preventDefault();

        const newUser = {
            fullName,
            username,
            password,
            city
        };

        dispatch(registerUser(newUser))
            .then(() => {
                setShowToast(true); // Show toast on successful registration
                setTimeout(() => setShowToast(false), 5000); // Hide toast after 5 seconds
            });
    };

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    return (
        <div className='home'>
            <div className="home-header">
                <h1>Admin Panel</h1>
                <h4>Xush Kelibsiz !</h4>
            </div>
            <div className="stat-content">
                <div className="stat">
                    <div className="icon">
                        <LuUsers2 />
                    </div>
                    <div className="text">
                        <p>Jami foydalanuvchilar</p>
                        <h5>{userCount}</h5>
                    </div>
                </div>
                <div className="stat">
                    <div className="icon">
                        <LuServer />
                    </div>
                    <div className="text">
                        <p>Jami ma'lumotlar</p>
                        <h5>{groupCount}</h5>
                    </div>
                </div>
                <div className="stat">
                    <div className="icon">
                        <RiComputerLine />
                    </div>
                    <div className="text">
                        <p>Hozir tarmoqda</p>
                        <h5>{activeUserCount}</h5>
                    </div>
                </div>
            </div>

            <div className="table-container">
                <div className="table-header">
                    <h1>Foydalanuvchilar</h1>
                    <div className="headerActions">
                        <div className="search">
                            <IoSearch />
                            <input
                                type="text"
                                placeholder='Qidirish'
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </div>
                        <button className='addMember' onClick={toggleModal}>
                            <IoPersonAddOutline />
                            Foydalanuvchi Qo'shish
                        </button>
                    </div>

                </div>

                <table className="user-table">
                    <thead>
                        <tr>
                            <th>№</th>
                            <th>Foydalanuvchi</th>
                            <th>Login</th>
                            <th>Harbiy qism</th>
                            <th>Holati</th>
                            <th>Amallar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredItems.map((user, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                    <NavLink to={`/malumotlar/${user.city}`}>
                                        {user.fullName}
                                    </NavLink>
                                </td>
                                <td>{user.username}</td>
                                <td>{user.city}</td>
                                <td className='statusTd'>
                                    <span className={`dot ${user.isActive ? 'active' : 'inactive'}`}>
                                        <FaDotCircle />
                                    </span>
                                    <span>{user.isActive ? 'Faol' : 'Faol emas'}</span>
                                </td>
                                <td className='actions'>
                                    <div className='actionBtn'>
                                        <button className='delete'>O'chirish</button>
                                        <button className='edit'>O'zgartirish</button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="pagination">
                    <ul>
                        {[...Array(totalPages)].map((_, index) => (
                            <li key={index + 1} className={currentPage === index + 1 ? 'active' : ''}>
                                <a href="/" onClick={(e) => handleClick(e, index + 1)}>{index + 1}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            <Modal show={showModal} onClose={toggleModal} handleSubmit={handleSubmit}>
                <form className='register' onSubmit={handleSubmit}>
                    <div className="newLogin">
                        <div>
                            <p>Ism Familya*</p>
                            <input
                                type="text"
                                placeholder="Ism Familya"
                                value={fullName}
                                onChange={(e) => setFullName(e.target.value)}
                            />
                        </div>
                        <div>
                            <p>Harbiy qism*</p>
                            <input
                                type="text"
                                placeholder='Harbiy okrug'
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='newLogin'>
                        <div>
                            <p>Yangi Login*</p>
                            <input
                                type="text"
                                placeholder="Login"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>

                        <div>
                            <p>Yangi Parol*</p>
                            <input
                                type="text"
                                placeholder='Parol'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                    </div>
                </form>
            </Modal>

            {showToast && <Toast message="Foydalanuvchi muvaffaqiyatli qo'shildi!" />}
            {/* Conditionally render Toast */}
        </div>
    );
};

export default Home;
