import React from 'react';
import './modal.scss';

const Modal = ({ show, onClose, children, handleSubmit }) => {
    if (!show) return null;

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
                <div className="modal-header">
                    <h4>Foydalanuvchi Qo'shish</h4>
                </div>
                <div className="modal-body">
                    {children}
                </div>
                <div className="modal-footer">
                    <button onClick={onClose} className="close-button">Ortga</button>
                    <button onClick={handleSubmit} className="save-button">Saqlash</button>
                </div>
            </div>
        </div>
    );
};

export default Modal;
