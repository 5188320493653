import {
    FETCH_GROUPS_REQUEST,
    FETCH_GROUPS_SUCCESS,
    FETCH_GROUPS_FAILURE
} from '../actions/type';

const initialState = {
    loading: false,
    specialGroups: [],
    ordinaryGroups: [],
    error: null,
};

const groupReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_GROUPS_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_GROUPS_SUCCESS:
            return {
                ...state,
                loading: false,
                specialGroups: action.payload.specialGroups,
                ordinaryGroups: action.payload.ordinaryGroups,
                error: null
            };
        case FETCH_GROUPS_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default groupReducer;