// src/redux/actions/userActions.js
import {
    FETCH_USERS_REQUEST,
    FETCH_USERS_SUCCESS,
    FETCH_USERS_FAILURE
} from './type';

export const fetchUsers = () => {
    return async (dispatch) => {
        dispatch({ type: FETCH_USERS_REQUEST });

        try {
            const token = sessionStorage.getItem('token');
            const response = await fetch('https://api.jismoniy-tayyorgarlik.uz/c93de48dc431a735217c95fe93e6d27a2bad42788a020bfff5ff1fe0aed45eea/', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            const data = await response.json();

            if (response.ok) {
                dispatch({ type: FETCH_USERS_SUCCESS, payload: data });
            } else {
                dispatch({ type: FETCH_USERS_FAILURE, payload: data.message });
            }
        } catch (error) {
            dispatch({ type: FETCH_USERS_FAILURE, payload: error.message });
        }
    };
};
