import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { RiDashboardLine } from "react-icons/ri";
import './navigation.scss';

const Navigation = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const location = useLocation();

    useEffect(() => {
        switch (location.pathname) {
            case '/malumotlar':
                setActiveIndex(1);
                break;
            default:
                setActiveIndex(0);
        }
    }, [location.pathname]);

    const handleSetActive = (index) => {
        setActiveIndex(index);
    };

    return (
        <div className="navigation">
            <ul>
                <li className={`list ${activeIndex === 0 ? "active" : ""}`}>
                    <NavLink
                        to="/"
                        className={({ isActive }) => isActive ? "active" : ""}
                        onClick={() => handleSetActive(0)}
                    >
                        <span className="icon">
                            <RiDashboardLine />
                        </span>
                        <span className="text">Monitoring</span>
                    </NavLink>
                </li>
                {/* <li className={`list ${activeIndex === 1 ? "active" : ""}`}>
                    <NavLink
                        to="/malumotlar/*"
                        className={({ isActive }) => isActive ? "active" : ""}
                        onClick={() => handleSetActive(1)}
                    >
                        <span className="icon">
                            <ion-icon name="person-outline"></ion-icon>
                        </span>
                        <span className="text">Profile</span>
                    </NavLink>
                </li> */}
                <div
                    className="indicator"
                    style={{ transform: `translateX(calc(70px * ${activeIndex}))` }}
                ></div>
            </ul>
        </div>
    );
};

export default Navigation;
