import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/actions';
import './login.scss'
const Login = ({ onLoginSuccess }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        try {
            const action = await dispatch(login({ username, password }));

            if (action && action.type === 'LOGIN_SUCCESS') {
                onLoginSuccess(); 
            } else if (action && action.payload) {
                setError(action.payload);
            } else {
                setError('An unknown error occurred');
            }
        } catch (error) {
            setError('Login failed: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='login'>
            <form onSubmit={handleLogin}>
                <div>
                    <label>Login</label>
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Parol</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" disabled={loading}>
                    {loading ? 'Logging in...' : 'Login'}
                </button>
                {error && <p style={{ color: 'red' }}>{error}</p>}
            </form>
        </div>
    );
};

export default Login;
