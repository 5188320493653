import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE
} from './type'
export const login = (credentials) => {
    return async (dispatch) => {
        dispatch({ type: LOGIN_REQUEST });

        try {
            const response = await fetch('https://api.jismoniy-tayyorgarlik.uz/287f471fb2a3f42aef383cef4a32be2731dc312254a86f166586c32dc6f80b55/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(credentials),
            });

            const data = await response.json();
            console.log("Response OK:", response.ok);
            console.log("Response Data:", data);

            if (response.ok && data.user && data.user.isAdmin) {
                sessionStorage.setItem('token', data.token);
                const successAction = { type: LOGIN_SUCCESS, payload: data.user };
                dispatch(successAction);
                return successAction;  // Return the action
            } else {
                const errorMessage = data.message || "Noma'lum xatolik yuz berdi";
                console.log("Login failed:", errorMessage);
                const failureAction = { type: LOGIN_FAILURE, payload: errorMessage };
                dispatch(failureAction);
                return failureAction;  // Return the action
            }

        } catch (error) {
            console.error("Error occurred during login:", error);
            const failureAction = { type: LOGIN_FAILURE, payload: error.message };
            dispatch(failureAction);
            return failureAction;  // Return the action
        }
    };
};
