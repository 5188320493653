import React, { useState } from 'react';
import axios from 'axios';
import './about.scss'
const About = () => {
 
  
  return (
    <div>
      
    </div>
  );
}

export default About;
