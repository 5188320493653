import {
    FETCH_GROUPS_REQUEST,
    FETCH_GROUPS_SUCCESS,
    FETCH_GROUPS_FAILURE
} from './type';

export const fetchGroups = () => {
    return async (dispatch) => {
        dispatch({ type: FETCH_GROUPS_REQUEST });

        try {
            const token = sessionStorage.getItem('token');
            const response = await fetch('https://api.jismoniy-tayyorgarlik.uz/b9462645f7363a00c427b9fa9276776dc259cd8f2158723eb5bf5928a1c783d0', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            const data = await response.json();

            if (response.ok) {
                dispatch({ type: FETCH_GROUPS_SUCCESS, payload: data });
            } else {
                dispatch({ type: FETCH_GROUPS_FAILURE, payload: data.message });
            }
        } catch (error) {
            dispatch({ type: FETCH_GROUPS_FAILURE, payload: error.message });
        }
    };
};