import './App.css';
import React, { useState, useEffect } from 'react';
import Navigation from './components/Navigation/Navigation';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Home from './components/Home/Home';
import About from './components/About/About';
import Login from './auth/Login/Login';
import UserDetails from './components/UserDetails/UserDetails';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  const handleLoginSuccess = () => {
    setIsAuthenticated(true);
    navigate('/');  // Navigate to home page after login
  };

  if (!isAuthenticated) {
    return (
      <Routes>
        <Route path="*" element={<Login onLoginSuccess={handleLoginSuccess} />} />
      </Routes>
    );
  }

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/malumotlar/:city" element={<UserDetails />} />
      </Routes>
      <div className='nav'>
        <Navigation />
      </div>
    </div>
  );
}

export default App;
