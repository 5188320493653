import React, { useEffect, useState } from 'react';
import { MdArrowBackIos } from "react-icons/md";
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Loader from '../Loader/Loader';
import { LuServer, LuUsers2 } from "react-icons/lu";
import { MdOutlineFolderSpecial } from "react-icons/md";
import { FaRegFolder } from "react-icons/fa";
import { IoSearch } from "react-icons/io5";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { Document, Packer, Paragraph } from "docx";
import './userDetails.scss';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
const UserDetails = () => {
    const { city } = useParams();
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [specialCount, setSpecialCount] = useState(0);
    const [ordinaryCount, setOrdinaryCount] = useState(0);
    const [gradeStats, setGradeStats] = useState({
        two: 0,
        three: 0,
        four: 0,
        five: 0,
    });
    // Pagination variables
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;

    // Search and filter variables
    const [searchTerm, setSearchTerm] = useState("");
    const [filterGroupType, setFilterGroupType] = useState("");
    const [filterAgeGroup, setFilterAgeGroup] = useState("");
    const [filterGrade, setFilterGrade] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = sessionStorage.getItem('token');
                const userIsAdmin = sessionStorage.getItem('isAdmin') === 'true';
                setIsAdmin(userIsAdmin);

                const response = await fetch('https://api.jismoniy-tayyorgarlik.uz/9c22468b26fa70722c3ba99a870eb3bc8cbf1315aeb2b0cefed6b731469d0bae', {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error("Ma'lumot yuklashda xatolik yuz berdi");
                }

                const { specialGroups, ordinaryGroups } = await response.json();
                let combinedData = [...specialGroups, ...ordinaryGroups];

                setSpecialCount(specialGroups.filter(item => item.city === city).length);
                setOrdinaryCount(ordinaryGroups.filter(item => item.city === city).length);

                combinedData = combinedData.filter(item => item.city === city);
                setUserData(combinedData);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [city]);

    // Handle pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    // Filter logic
    const filteredData = userData
        .filter(item => item.fullName?.toLowerCase().includes(searchTerm.toLowerCase()))
        .filter(item => filterGroupType === "" || item.groupType === filterGroupType)
        .filter(item => filterAgeGroup === "" || item.ageGroup === filterAgeGroup)
        .filter(item => filterGrade === "" || item.grade.toString() === filterGrade);

    const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Excel export function
    const exportToExcel = () => {
        const ws = XLSX.utils.json_to_sheet(currentData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "UsersData");
        XLSX.writeFile(wb, `UserData_${city}.xlsx`);
    };

    // Word export function
    const exportToWord = async () => {
        const doc = new Document({
            sections: [{
                children: currentData.map((item, index) => new Paragraph({
                    text: `${index + 1}. ${item.fullName || 'Noma`lum'} - ${item.groupType}, ${item.ageGroup}, Ball: ${item.score}, Baho: ${item.grade}`
                }))
            }]
        });

        const blob = await Packer.toBlob(doc);
        saveAs(blob, `UserData_${city}.docx`);
    };

    useEffect(() => {
        const total = filteredData.length;

        if (total > 0) {
            const twoCount = filteredData.filter(item => item.grade === 2).length;
            const threeCount = filteredData.filter(item => item.grade === 3).length;
            const fourCount = filteredData.filter(item => item.grade === 4).length;
            const fiveCount = filteredData.filter(item => item.grade === 5).length;

            setGradeStats({
                two: (twoCount / total) * 100,
                three: (threeCount / total) * 100,
                four: (fourCount / total) * 100,
                five: (fiveCount / total) * 100,
            });
        } else {
            setGradeStats({
                two: 0,
                three: 0,
                four: 0,
                five: 0,
            });
        }
    }, [filteredData]);

    if (loading) return <Loader />;
    if (error) return <p>Xatolik: {error}</p>;

    return (
        <div className='details'>
            <div className="details-header">
                <Link to={'/'}>
                    <button>
                        <MdArrowBackIos />
                        Bosh sahifaga qaytish
                    </button>
                </Link>
                <h4>{`${city} - harbiy qism ma'lumotlari`}</h4>
            </div>

            <div className="stat-content">
                <div className="stat">
                    <div className="icon">
                        <LuServer />
                    </div>
                    <div className="text">
                        <p>Jami ma'lumotlar</p>
                        <h5>{userData.length}</h5>
                    </div>
                </div>

                <div className="stat">
                    <div className="icon">
                        <MdOutlineFolderSpecial />
                    </div>
                    <div className="text">
                        <p>Maxsus bo'linma</p>
                        <h5>{specialCount}</h5>
                    </div>
                </div>

                <div className="stat">
                    <div className="icon">
                        <FaRegFolder />
                    </div>
                    <div className="text">
                        <p>Umum qo'shin bo'linma</p>
                        <h5>{ordinaryCount}</h5>
                    </div>
                </div>
            </div>

            <div className="progres-container">
                <div className="title">
                    <h1>Ma'lumotlar Foizda</h1>
                </div>
            <div className="progress-item">
                    <h4>2 Baho</h4>
                    <CircularProgressbar
                        value={gradeStats.two}
                        text={`${Math.round(gradeStats.two)}%`}
                        styles={buildStyles({ textSize: '16px', pathColor: '#f00', textColor: '#f00' })}
                    />
                </div>
                <div className="progress-item">
                    <h4>3 Baho</h4>
                    <CircularProgressbar
                        value={gradeStats.three}
                        text={`${Math.round(gradeStats.three)}%`}
                        styles={buildStyles({ textSize: '16px', pathColor: '#ffa500', textColor: '#ffa500' })}
                    />
                </div>
                <div className="progress-item">
                    <h4>4 Baho</h4>
                    <CircularProgressbar
                        value={gradeStats.four}
                        text={`${Math.round(gradeStats.four)}%`}
                        styles={buildStyles({ textSize: '16px', pathColor: '#00f', textColor: '#00f' })}
                    />
                </div>
                <div className="progress-item">
                    <h4>5 Baho</h4>
                    <CircularProgressbar
                        value={gradeStats.five}
                        text={`${Math.round(gradeStats.five)}%`}
                        styles={buildStyles({ textSize: '16px', pathColor: '#0f0', textColor: '#0f0' })}
                    />
                </div>
            </div>

            <div className="table-container">
                <div className="table-header">
                    <h1>Ma'lumotlar</h1>
                    <div className="headerActions">
                        <div className="search">
                            <IoSearch />
                            <input
                                type="text"
                                placeholder='Qidirish'
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>

                        {/* Filters */}
                        <div className="filters">
                            <select onChange={(e) => setFilterGroupType(e.target.value)} value={filterGroupType}>
                                <option value="">Bo'linma turi</option>
                                <option value="Maxsus bo'linma">Maxsus bo'linma</option>
                                <option value="Umum qo'shin bo'linma">Umum qo'shin</option>
                            </select>
                            <select onChange={(e) => setFilterAgeGroup(e.target.value)} value={filterAgeGroup}>
                                <option value="">Yosh guruhi</option>
                                <option value="I">I</option>
                                <option value="II">II</option>
                                <option value="III">III</option>
                            </select>
                            <select onChange={(e) => setFilterGrade(e.target.value)} value={filterGrade}>
                                <option value="">Baho</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                            </select>
                        </div>

                        {/* Export buttons */}
                        <div className="export-buttons">
                            <button className="excel-btn" onClick={exportToExcel}>Excelga yuklab olish</button>
                            <button className="word-btn" onClick={exportToWord}>Wordga yuklab olish</button>
                        </div>
                    </div>
                </div>

                <table className='user-table'>
                    <thead>
                        <tr>
                            <th>№</th>
                            <th>F.I.Sh</th>
                            <th>Bo'linma Turi</th>
                            <th>Yosh Guruhi</th>
                            <th>Umumiy ball</th>
                            <th>Baho</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentData.map((item, index) => (
                            <tr key={index}>
                                <td>{indexOfFirstItem + index + 1}</td>
                                <td>{item.fullName || 'Noma`lum'}</td>
                                <td>{item.groupType}</td>
                                <td>{item.ageGroup}</td>
                                <td>{item.score}</td>
                                <td>{item.grade}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className="pagination">
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index}
                            className={index + 1 === currentPage ? 'active' : ''}
                            onClick={() => handlePageChange(index + 1)}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default UserDetails;
