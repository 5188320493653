// src/redux/reducers/index.js
import { combineReducers } from 'redux';
import authReducer from './authReducer';
import userReducer from './userReducer';
import groupReducer from './groupReducer';

const rootReducer = combineReducers({
    auth: authReducer,
    users: userReducer,
    groups: groupReducer,
});

export default rootReducer;
