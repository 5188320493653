import {
    REGISTER_REQUEST,
    REGISTER_SUCCESS,
    REGISTER_FAILURE
} from './type';
import axios from 'axios'
export const registerUser = (userData) => async (dispatch) => {
    dispatch({ type: REGISTER_REQUEST });

    try {
        const response = await axios.post('https://api.jismoniy-tayyorgarlik.uz/287f471fb2a3f42aef383cef4a32be2731dc312254a86f166586c32dc6f80b55/register', userData);
        dispatch({
            type: REGISTER_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: REGISTER_FAILURE,
            payload: error.response ? error.response.data.message : error.message,
        });
    }
};