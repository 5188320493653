// src/components/Loader.js
import React from 'react';
import './Loader.scss'; // Create this file for styles

const Loader = () => {
    return (
        <div>

        <div className="loader-container">
            <div className="loader"></div>
            <p>Bir soniya !</p>
        </div>
        </div>
    );
};

export default Loader;
